import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import env from "../config/enviroment";

const EditUserInfo = () => {
  const location = useLocation();
  const { userInfo } = location.state || {};
  const [initialData, setInitialData] = useState({});
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    paternalSurname: "",
    maternalSurname: "",
    birthDate: "",
    rfc: "",
    interbankKey: ""
  });
  
  useEffect(() => {
    if (userInfo) {
      const initialValues = {
        firstName: userInfo["Informacion del Usuario"]["Informacion Personal"].firstName || "",
        middleName: userInfo["Informacion del Usuario"]["Informacion Personal"].middleName || "",
        paternalSurname: userInfo["Informacion del Usuario"]["Informacion Personal"].paternalSurname || "",
        maternalSurname: userInfo["Informacion del Usuario"]["Informacion Personal"].maternalSurname || "",
        birthDate: userInfo["Informacion del Usuario"]["Informacion Personal"].birthDate || "",
        rfc: userInfo["Informacion del Usuario"]["SAT"].rfc || "",
        interbankKey: userInfo["Informacion del Usuario"]["Informacion Financiera"]["Clabe Bancaria"] || ""
      };
      setFormData(initialValues);
      setInitialData(initialValues);
    }
  }, [userInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
//console.log(formData);
  const handleSubmit = async (e, validateClabe = false) => {
    e.preventDefault();

    const customerId = userInfo["Informacion del Usuario"].customerId;
    
    const updatedFields = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        if (["firstName", "middleName", "paternalSurname", "maternalSurname"].includes(key)) {
          acc.basicPersonalInfo = { ...acc.basicPersonalInfo, [key]: formData[key] };
        } else if (key === "birthDate") {
          acc.personalInfo = { ...acc.personalInfo, [key]: formData[key] };
        } else if (key === "rfc") {
          acc.taxpayer = { ...acc.taxpayer, [key]: formData[key] };
        } else if (key === "interbankKey") {
          acc.financialInformation = { ...acc.financialInformation, [key]: formData[key] };
        }
      }
      return acc;
    }, {}); 

    try {
      console.log("customer recibido: ", customerId);
      console.log("data que se va a enviar recibido: ", updatedFields);
      const response = await axios.put(
        `${env.API_URL}/customerService/informacionUsuario/${customerId}`,
        updatedFields,
        {
          headers: {
            "api-key": env.API_KEY_MIDDLEWARE,
          },
        }
      );
      console.log("Respuesta del update customer:", response.data);

      if (validateClabe) {
        // Llamada adicional al endpoint para validar la CLABE
        console.log("Validando CLABE...");
        const clabeResponse = await axios.put(
          `${env.API_URL}/customerService/informacionUsuario/${customerId}?validateClabe=true`,
          updatedFields,
          {
            headers: {
              "api-key": env.API_KEY_MIDDLEWARE,
            },
          }
        );
        console.log("Respuesta de validación CLABE:", clabeResponse.data);
        swal("CLABE enviada a validación exitosamente");
      }

      swal("Información actualizada con éxito");
      navigate(-1);
    } catch (error) {
      console.error("Error al actualizar la información: ", error);
      swal("Hubo un error al actualizar la información.");
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "300px",
        margin: "0 auto",
        gap: 2,
      }}
    >
      <Typography variant="h5" align="center">
        Editar Información del Usuario
      </Typography>
      <Typography variant="h7" align="center">
        *ATENCIÓN: la modificación de estos datos recalcula automáticamente el CURP del usuario. Los campos no son obligatorios.
      </Typography>
      <span></span>
      <TextField
        label="Primer Nombre"
        name="firstName"
        value={formData.firstName}
        onChange={handleInputChange}
        
      />
      <TextField
        label="Segundo Nombre"
        name="middleName"
        value={formData.middleName}
        onChange={handleInputChange}
      />
      <TextField
        label="Apellido Paterno"
        name="paternalSurname"
        value={formData.paternalSurname}
        onChange={handleInputChange}
        
      />
      <TextField
        label="Apellido Materno"
        name="maternalSurname"
        value={formData.maternalSurname}
        onChange={handleInputChange}
      />
      <TextField
        label="Fecha de Nacimiento"
        name="birthDate"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={formData.birthDate}
        onChange={handleInputChange}
        
      />
      <TextField
        label="RFC"
        name="rfc"
        value={formData.rfc}
        onChange={handleInputChange}
        
      />

      <Button
        variant="contained"
        color="secondary"
        onClick={(e) => handleSubmit(e, false)}
      >
        Guardar
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={(e) => handleSubmit(e, true)}
      >
        Guardar y validar clabe
      </Button>
    </Box>
  );
};

export default EditUserInfo;
