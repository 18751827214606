import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import env from "../config/enviroment";

const EditClabeInfo = () => {
  const location = useLocation();
  const { userInfo } = location.state || {};
  const [initialData, setInitialData] = useState({});
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    interbankKey: "",
    customerId: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  console.log(formData);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const customerId = formData.customerId;
    const updatedFields = {
      interbankKey: formData.interbankKey,
    };
    try {
      console.log("customer seteado: ", customerId);
      console.log("data que se va a enviar recibido: ", updatedFields);

      const response = await axios.put(
        `${env.API_URL}/customerService/informacionUsuario/${customerId}`,
        updatedFields,
        {
          headers: {
            "api-key": env.API_KEY_MIDDLEWARE,
          },
        }
      );
      console.log("Respuesta del update customer:", response.data);
      swal("Información actualizada con éxito");
      navigate(-1);
    } catch (error) {
      console.error("Error al actualizar la información: ", error);
      swal("Hubo un error al actualizar la información.");
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "300px",
        margin: "0 auto",
        gap: 2,
      }}
    >
      <Typography variant="h5" align="center">
        Editar Clabe del Usuario
      </Typography>
      <Typography variant="h7" align="center">
        *ATENCIÓN: la modificación de la clabe la envía automáticamente a
        validación.
      </Typography>
      <span></span>
      <TextField
        label="Nueva clabe bancaria"
        name="interbankKey"
        value={formData.interbankKey}
        onChange={handleInputChange}
      />
      <TextField
        label="Customer Id"
        name="customerId"
        value={formData.customerId}
        onChange={handleInputChange}
      />

      <Button
        variant="contained"
        color="secondary"
        onClick={(e) => handleSubmit(e)}
      >
        Guardar
      </Button>
    </Box>
  );
};

export default EditClabeInfo;
