import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Divider,
  CircularProgress,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../services/UserContext";
import { admin } from "../config/levelGroups";

function UserInformation() {
  const [customerId, setCustomerId] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  const fetchUserInfo = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${env.API_URL}/customerService/informacionUsuario/${customerId}`,
        {
          headers: {
            "api-key": env.API_KEY_MIDDLEWARE,
          },
        }
      );
      setUserInfo(response.data);
      setCustomerId("");
    } catch (error) {
      console.error("Error fetching user information:", error);
      if (
        error.response &&
        (error.response.status === 400 || error.response.status === 500)
      ) {
        swal("Error", "No existe el customer", "error");
      } else {
        swal("Error", "No se pudo obtener la información del usuario", "error");
      }
      setCustomerId("");
    } finally {
      setLoading(false);
    }
  };

  const handleCustomerIdChange = (event) => {
    setCustomerId(event.target.value);
  };

  const handleFetchUserInfo = () => {
    fetchUserInfo();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleFetchUserInfo();
    }
  };

  const handleEditClick = () => {
    navigate("/update/customer", { state: { userInfo } });
  };

  const renderProperty = (property, value) => {
    if (typeof value === "object" && value !== null) {
      return (
        <Box key={property} sx={{ ml: 2 }}>
          <Typography variant="h6" color="primary">
            {property}
          </Typography>
          {Object.entries(value).map(([key, val]) => (
            <Box key={key} sx={{ ml: 2 }}>
              {renderProperty(key, val)}
            </Box>
          ))}
        </Box>
      );
    } else if (property.toLowerCase() === "clientid") {
      return (
        <Typography key={property} sx={{ ml: 2 }}>
          <strong style={{ color: "black" }}>{property}:</strong> {value}{" "}
          <Link
            href={`https://portal.intelligential.tech/front-general/current/customer-file/${value}?redirect=%2Fcustomers%3Fpage%3D1&search=&customerType=&customerId=${value}&type=ASC&filter=&origin=customers`}
            target="_blank"
          >
            Ver Cliente
          </Link>
        </Typography>
      );
    } else if (
      property.toLowerCase() === "firstname" &&
      user &&
      admin.includes(user.email)
    ) {
      return (
        <Typography key={property} sx={{ ml: 2 }}>
          <strong>{property}:</strong> {value}{" "}
          <Button variant="text" color="primary" onClick={handleEditClick}>
            Editar
          </Button>
        </Typography>
      );
    } else if (property.toLowerCase() === "lastcontractid" && value) {
      return (
        <Typography key={property} sx={{ ml: 2 }}>
          <strong style={{ color: "black" }}>{property}:</strong> {value}{" "}
          <Link
            href={`https://portal.intelligential.tech/front-core/current/resume-credit/${value}`}
            target="_blank"
          >
            Ver contrato
          </Link>
        </Typography>
      );
    } else if (property.toLowerCase() === "softcreditclientid" && value) {
      return (
        <Typography key={property} sx={{ ml: 2 }}>
          <strong style={{ color: "black" }}>{property}:</strong> {value}{" "}
          <Link
            href={`https://softcredito.com/produccion/TRUECAPITAL/app/clientes/update/${value}`}
            target="_blank"
          >
            Ver en SoftCredito
          </Link>
        </Typography>
      );
    } else {
      return (
        <Typography key={property} sx={{ ml: 2 }}>
          <strong
            style={{
              color:
                property.toLowerCase() === "customerid" ||
                property.toLowerCase() === "softcreditclientid" ||
                property.toLowerCase() === "direcciones" ||
                property.toLowerCase() === "informacion personal" ||
                property.toLowerCase() === "registracion nuovopay" ||
                property.toLowerCase() === "sat" ||
                property.toLowerCase() === "informacion financiera" ||
                property.toLowerCase() === "informacion de celular" ||
                property.toLowerCase() === "informacion del credito" ||
                property.toLowerCase() === "informacion del loan"
                  ? "red"
                  : "black",
            }}
          >
            {property}:
          </strong>{" "}
          {JSON.stringify(value)}
        </Typography>
      );
    }
  };

  useEffect(() => {}, []);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>
        Información del Usuario
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <TextField
          label="Ingrese customerId"
          variant="outlined"
          value={customerId}
          onChange={handleCustomerIdChange}
          onKeyPress={handleKeyPress}
          fullWidth
          disabled={loading}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleFetchUserInfo}
          sx={{ ml: 2 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Obtener Información"}
        </Button>
      </Box>

      {userInfo ? (
        <Paper sx={{ p: 3 }}>
          {Object.entries(userInfo).map(([section, properties]) => (
            <Box key={section} sx={{ mb: 3 }}>
              <Typography variant="h5" color="secondary" gutterBottom>
                {section}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {Object.entries(properties).map(([property, value]) => (
                <Box key={property} sx={{ ml: 2, mb: 1 }}>
                  {renderProperty(property, value)}
                </Box>
              ))}
            </Box>
          ))}
        </Paper>
      ) : (
        <Typography variant="body1" color="textSecondary">
          Ingrese un customerId y haga clic en el botón para obtener la
          información del usuario.
        </Typography>
      )}
    </Box>
  );
}

export default UserInformation;
