import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./services/UserContext"; // Importa el contexto
import Dashboard from "./pages/Dashboard";
import AdminPanel from "./components/AdminPanel";
import BlackList from "./components/BlackList";
import UserInformation from "./components/UserInformation";
// import UsersInformationBasic from "./components/UsersInformationBasic";
import EnrollmentVerify from "./components/EnrollmentVerify";
import UnrollmentManualy from "./components/UnrollmentManualy";
import SendEmailApproved from "./components/SendEmailApproved";
import GenerateClabe from "./components/GenerateClabe";
import NuovoPay from "./components/NuovoPay";
import RenovationExceptional from "./components/RenovationExceptional";
import PrivateRoute from "./services/PrivateRoute";
import NextCreditProjection from "./components/NextCreditProjection";
import Reports from "./components/Reports";
import { basicAccess, supervisor, admin, testing } from "./config/levelGroups";
import EditClabeInfo from "./components/EditClabeInfo";
import EditUserInfo from "./components/EditUserInfo";

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/admin"
            element={<PrivateRoute element={<AdminPanel />} allowedEmails={basicAccess} />}
          />
          <Route
            path="/BlackList"
            element={<PrivateRoute element={<BlackList />} allowedEmails={basicAccess} />}
          />
          <Route
            path="/UserInformation"
            element={<PrivateRoute element={<UserInformation />} allowedEmails={basicAccess} />}
          />
          <Route
            path="/EnrollmentVerify"
            element={<PrivateRoute element={<EnrollmentVerify />} allowedEmails={basicAccess} />}
          />
          <Route
            path="/UnrollmentManualy"
            element={<PrivateRoute element={<UnrollmentManualy />} allowedEmails={admin} />}
          />
          <Route
            path="/SendEmailApproved"
            element={<PrivateRoute element={<SendEmailApproved />} allowedEmails={basicAccess} />}
          />
          <Route
            path="/generateClabe"
            element={<PrivateRoute element={<GenerateClabe />} allowedEmails={basicAccess} />}
          />
          <Route
            path="/NuovoPay"
            element={
              <PrivateRoute
                element={<NuovoPay />}
                allowedEmails={[...basicAccess, 'plata@finnix.ai']}
              />
            }
          />

          {/* <Route
            path="/RenovationExceptional"
            element={<PrivateRoute element={<RenovationExceptional />} allowedEmails={admin} />}
          /> */}
          {/* <Route
            path="/NextCreditProjection"
            element={<PrivateRoute element={<NextCreditProjection />} allowedEmails={basicAccess} />}
          /> */}
          {/* <Route
            path="/reports"
            element={<PrivateRoute element={<Reports />} allowedEmails={supervisor} />}
          /> */}
          <Route
            path="/validate/clabe"
            element={<EditClabeInfo />}
          />
          <Route
            path="/update/customer"
            element={<EditUserInfo />}
          />
          <Route path="/Blocked" element={<div>Access Denied</div>} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
