import React, { useState, useEffect } from "react";
import axios from "axios";
import env from "../config/enviroment";

function NuovoPayStatus() {
  const [nuovoPayActive, setNuovoPayActive] = useState(null);

  useEffect(() => {
    // Consultar el estado de NuovoPay al montar el componente
    axios
      .get("https://api.finnix.ai/enviromentGlobal", {
        headers: {
          "api-key": env.API_KEY_FABISAN,
        },
      })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setNuovoPayActive(response.data[0].NUOVOPAY_ACTIVE);
        }
      })
      .catch((error) => {
        console.error("Error al consultar el estado de NuovoPay:", error);
      });
  }, []);

  // Mostrar un mensaje de carga mientras se obtiene el estado
  if (nuovoPayActive === null) {
    return <div>Cargando estado de NuovoPay...</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "10px",
        padding: "10px",
        border: "1px solid #ccc", // Borde para el marco
        borderRadius: "8px", // Bordes redondeados
        backgroundColor: "#f9f9f9", // Fondo del marco
        marginLeft: "-20px", // Corrección hacia la izquierda
        width: "fit-content", // Ajusta el tamaño al contenido
      }}
    >
      <span>NuovoPay:</span>
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          backgroundColor: nuovoPayActive ? "green" : "red",
        }}
      ></div>
      <span>{nuovoPayActive ? "Activo" : "Inactivo"}</span>
    </div>
  );
}

export default NuovoPayStatus;
